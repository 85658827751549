import React from 'react';
import logo from '../assets/logo.svg';

import { t } from '../lang';
const PrivacyPolicy = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
        <p><strong>Публичная Оферта</strong><br />
          (Для платформы Road 24)</p>

        <p>Г. Ташкент<br />
          01.01.2024</p>

        <p>Эта оферта является официальной публичной офертой, направленной на неограниченное количество юридических и физических лиц, заключающих договор на оказание услуг по использованию платформы “Road24” компанией Kash APP LLC (далее-администрация или оператор). предоставление услуг по предоставлению.</p>

        <p>Выполнение действий, указанных в настоящей оферте, является безусловным и безусловным подтверждением согласия (принятия) лицами, зарегистрированными на платформе, на заключение договора на оказание услуг на условиях, в порядке и объеме, установленных настоящей офертой (далее – договор).</p>

        <p><strong>1. ОСНОВНЫЕ ПОНЯТИЯ</strong></p>

        <p>1.1. В настоящем договоре используются следующие основные понятия:</p>

        <p>Администрация (администратор сайта или оператор), предоставляемая ООО” KASH App«, является разработчиком платформы» Road24".</p>

        <p>Платформа "Road24" (сайт (https://Road24.uz) и приложение или сервис в тексте, сайт) - это программа в виде онлайн-платформы (для ПК), которая позволяет пользователям получать ряд инновационных и других услуг. Платформа является официально зарегистрированным объектом авторского права.</p>

        <p>Пользователь-физическое или юридическое лицо, которое начинает пользоваться платформой и ее функциями, пользуется услугами и платит за них, имеет собственный личный кабинет на платформе;</p>

        <p>Организации-партнеры-банки, страховые организации, рекламодатели и другие организации, предоставляющие свои услуги через платформу Road24;</p>

        <p>Услуги (сервис) - посреднические, информационные и консультационные услуги, предоставляемые пользователям через платформу Road24;</p>

        <p>1.2. В этом договоре также могут использоваться условия, не указанные в пункте 1.1. Значение таких терминов следует понимать в соответствии с настоящим гражданским и иным законодательством, а также текстом договора.</p>

        <p><strong>2. ПОРЯДОК ПРИЕМА</strong></p>

        <p>2.1. Принятие этой публичной оферты осуществляется путем подтверждения регистрации пользователя на платформе» Road24". Платформа работает на платформах IOS, Android и на сайте www.»Road24».uz. Договор считается заключенным с момента принятия предложения.</p>

        <p>2.2. Если пользователь не согласен с условиями настоящего Соглашения, он должен прекратить использование сайта (платформы).</p>

        <p>2.3. Если пользователь нарушает данное предложение, администрация сайта в одностороннем порядке может полностью или частично расторгнуть настоящее соглашение или наложить ограничения.</p>

        <p>2.4. Прекращение оказания услуг платформой означает прекращение действия договора между администрацией и Пользователем.</p>

        <p>2.5. Территория оферты-Республика Узбекистан (далее следует Узбекистан). Res. ) является.</p>

        <p><strong>3. ЦЕЛЬ КОНТРАКТА</strong></p>

        <p>3.1. Предметом настоящего Договора является взаимовыгодное сотрудничество сторон в сфере получения услуг клиентами (пользователями) посредством регистрации пользователей на платформе. В том числе разрешения на изменение тона (тонирование) стекол автомобилей, страховые полисы и т.д.</p>

        <p>3.1.1. Возможность получения разрешений на изменение тона (тонирование) стекол автотранспортных средств в установленном законодательством порядке, документов на оформление страхового полиса реализуется в процессе тестирования.</p>

        <p>3.2. В соответствии с настоящим Соглашением пользователь пользуется платными услугами платформы, соблюдает условия пользования и обязуется в установленном порядке уплачивать брокерские сборы за оказанные платные услуги. Администрация обеспечивает бесперебойную работу платформы, предоставляет пользователям платный доступ к услугам и возможностям платформы и взимает плату в установленном порядке. При этом оператор обеспечивает только техническое и информационное взаимодействие при осуществлении услуг и иных операций, предусмотренных функциональностью платформы, не запрещенных законодательством Республики Узбекистан, и не несет ответственности за снятие и перечисление соответствующих средств с расчетных счетов (карт) клиентов в пользу организаций-партнеров.</p>

        <p>3.3. Во всех случаях, прямо не предусмотренных договором, стороны обязаны соблюдать действующее законодательство и обычаи предпринимательской деятельности Республики Узбекистан. Принятие пользователем настоящего договора означает также принятие следующих документов:</p>

        <p>- ”Политика конфиденциальности персональных данных пользователей Road24” (находится по адресу https://»Road24».uz/privacy-Policy/). Данный документ является неотъемлемой частью настоящего Соглашения.</p>

        <p>3.4. Новые редакции документов вступают в силу со дня их опубликования, если иное не предусмотрено новыми редакциями документов. Тот факт, что пользователь продолжает пользоваться услугами платформы даже после внесения изменений и дополнений в соглашение, означает, что изменения и дополнения были приняты им.</p>

        <p>3.5. Прежде чем воспользоваться услугами платформы, пользователь должен полностью ознакомиться с этим предложением и ознакомиться с ценами, указанными в тарифном плане. Принятие настоящей публичной оферты осуществляется путем регистрации Пользователя на платформе и в соответствии с требованиями Гражданского кодекса Республики Узбекистан. 357, 364, 365, 366, 367, 369 и в соответствии со статьями 370 означает полное и безоговорочное соблюдение оферты. .</p>

        <p>3.6. Этот договор является открытым и общедоступным документом.</p>

        <p>3.7. Заключая настоящее Соглашение, пользователь дает оператору полное, неограниченное согласие на обработку его персональных данных в соответствии с требованиями законодательства Республики Узбекистан О персональных данных. Предоставляет право на защиту и обработку персональных данных, размещенных на Интернет-ресурсе оператора.</p>

        <p>3.8. Данное предложение разработано на основе Гражданского кодекса Республики Узбекистан, законов Республики Узбекистан "Об электронной коммерции", "об электронном документообороте" и др.</p>

        <p><strong>4. СТАТУС ПЛАТФОРМЫ</strong></p>

        <p>4.1. Платформа-интернет-ресурс (информационный посредник, веб-сайт, сервис), представляющий компьютерные программы и наборы данных, предоставляющие такую информацию в сети Интернет по адресу https://»Road24».uz.</p>

        <p>4.2. https: / / сетевой адрес»Road24". uz (доменное имя) и все права, связанные с его использованием, принадлежат администрации платформы. Доступ заинтересованных лиц к сайту определяется настоящим Договором в соответствии с действующим законодательством Республики Узбекистан.</p>

        <p>4.3. Право пользования результатами интеллектуальной деятельности принадлежит администрации.</p>

        <p>4.4. Заявления, предложения и жалобы физических и юридических лиц, связанные с настоящим договором или деятельностью сайта, а также запросы компетентных органов принимаются на адрес электронной почты администрации или по электронной почте support@»Road24».uz или по номерам в Службе поддержки пользователей.</p>

        <p>4.5. Это соглашение никоим образом не дает пользователю права использовать название компании, товарный знак, доменное имя и другие узнаваемые символы администрации сайта. Право администрации сайта на использование фирменного наименования, товарного знака, доменного имени и других узнаваемых символов может быть предоставлено с письменного согласия администрации сайта.</p>

        <p>4.6. Управление и развитие сайта осуществляется администрацией в соответствии с законодательством Республики Узбекистан.</p>

        <p>4.7. Администрация платформы может оказывать дополнительные услуги с учетом предложений пользователей, дополнительно расширяя возможности платформы.</p>

        <p><strong>5. ПРАВА И ОБЯЗАННОСТИ СТОРОН</strong></p>

        <p>5.1. Администрация и пользователь(ы) являются сторонами настоящего Соглашения.</p>

        <p>5.2. Стороны настоящего договора имеют право:</p>

        <p>5.2.1. Администрация платформы имеет право:</p>

        <p>- Устанавливать, принимать, разрешать или отменять соединения для обслуживания Пользователем в порядке, установленном платформой;</p>

        <p>- взимание платы за платные услуги;</p>

        <p>- проведение рекламных кампаний на собственные средства и по собственному желанию с целью продвижения услуг, предлагаемых пользователям на платформе;</p>

        <p>- Поиск клиентов и предложение им пользовательских услуг, а также ознакомление с условиями предоставления услуг.</p>

        <p>5.2.2. Пользователь имеет следующие права:</p>

        <p>- принятие услуг платформы в соответствии с установленными условиями;</p>

        <p>- иметь доступ к платформе на согласованных условиях;</p>

        <p>- получение разъяснений и помощи от администрации в случае необходимости.</p>

        <p>5.3. Стороны настоящего Договора выполняют следующие обязательства.</p>

        <p>5.3.1. Администрация (оператор) платформы несет следующие обязанности:</p>

        <p>- своевременная оплата услуг в соответствии с условиями настоящего Договора;</p>

        <p>- действовать в строгом соответствии с требованиями и стандартами, предусмотренными настоящим договором, а также строго соблюдать действующее законодательство Республики Узбекистан в сфере услуг;</p>

        <p>- Действовать со строгим соблюдением технических требований к приему, проверке и отправке информационных параметров, а также правил работы с платформой;</p>

        <p>- Обеспечение конфиденциальности персональных данных пользователей в соответствии с политикой конфиденциальности (за исключением общедоступных данных);</p>

        <p>- Обеспечение и развитие бесперебойной и целенаправленной работы платформы Road24;</p>

        <p>- соблюдение условий настоящего Договора;</p>

        <p>- Требовать от пользователей правильного выполнения своих обязательств по настоящему Договору.</p>

        <p>5.3.2. Пользователь выполняет следующие обязанности:</p>

        <p>- соблюдение условий настоящего Договора;</p>

        <p>- предоставление точной и точной информации;</p>

        <p>- Не использовать в процессе пользования услугами сайта рекламные, эротические, порнографические и оскорбительные слова (информацию), противоречащие действующему законодательству Республики Узбекистан и способные нанести ущерб законным интересам третьих лиц;</p>

        <p>- не нарушать авторские права, товарные знаки, иные права интеллектуальной собственности третьих лиц (любых) на информацию (материалы), комментарии;</p>

        <p>- своевременная оплата оказанных платных услуг;</p>

        <p>- не использовать сайт в незаконных целях, не изменять его;</p>

        <p>- сохранение конфиденциальности личной информации и конфиденциальности других лиц;</p>

        <p>- использование платформы в порядке, установленном настоящим Соглашением;</p>

        <p>- Отслеживание сообщений для пользователей платформы, опубликованных оператором в соответствующих интерфейсах платформы, а также соблюдение требований, содержащихся в этих сообщениях;</p>

        <p>- не разглашать третьим лицам личную информацию и / или другую информацию, обеспечивающую доступ к платформе (далее - "информация для доступа к платформе");</p>

        <p>-Принять необходимые организационно-технические меры по предотвращению незаконного получения и использования третьими лицами учетных данных для доступа к платформе;</p>

        <p>- Незамедлительное уведомление оператора О фактах или подозрениях в незаконном использовании учетных данных третьими лицами для доступа к платформе, включая потерю доступа к номеру мобильного телефона (утерянные, украденные или иные утерянные средства).</p>

        <p>- Не совершение через платформу незаконных финансовых операций, незаконной торговли, операций, направленных на легализацию преступных доходов или финансирование терроризма, или иных действий, запрещенных законодательством Республики Узбекистан;</p>

        <p>- не использовать платформу для осуществления операций, направленных на систематическое получение прибыли или сокрытие доходов.</p>

        <p>5.4. Пользователь обязан предоставить платформе точную и точную информацию. В случае непредоставления или неполного предоставления пользователем информации администрации сайта администрация сайта может либо отменить подключение/регистрацию пользователя, либо зарегистрировать ее в иной форме, чем того пожелает пользователь.</p>

        <p>5.5. На платформе категорически запрещены действия, направленные на отвлечение других пользователей, а также:</p>

        <p>-создание нескольких учетных записей / присоединение к нескольким API, повторная регистрация, чтобы выглядеть как кто-то другой, особенно с именами или псевдонимами других компаний, юридических лиц;</p>

        <p>- присоединение на основе ложной информации, искажение информации, размещение дезинформации о себе и т. д;</p>

        <p>- размещение ненужной, ненужной информации на бланках заявок;</p>

        <p>- оставлять комментарии, которые искажают информацию до такой степени, что невозможно определить, на кого направлено сообщение;</p>

        <p>- Допускать грубые орфографические ошибки при использовании платформы сайта;</p>

        <p>- Оставлять комментарии и рейтинги о людях, которые не получали или не использовали свои услуги лично на платформе.</p>

        <p>- нарушение действующего законодательства Республики Узбекистан при оказании услуг.</p>

        <p>5.6. Все объекты, размещенные на платформе, включая элементы дизайна, текст, графику, изображения, видео, скрипты, программы и другие объекты и их комплексы (далее-контент), защищены авторским правом администрации сайта, пользователей сайта и других правообладателей.</p>

        <p>5.7. Никакой контент не может быть полностью или частично скопирован (перепечатан), обработан, распространен, показан, опубликован, загружен, передан, продан или приобретен, за исключением случаев, указанных в настоящем соглашении, и в соответствии с действующим законодательством Республики. Республика Узбекистан без предварительного согласия автора, за исключением случаев, когда у автора есть явное разрешение.</p>

        <p>5.8. Пользователь не имеет права загружать или публиковать результаты интеллектуальной деятельности, связанные с содержанием других сайтов, баз данных и других авторов, на платформе без согласия автора.</p>

        <p>5.9. Пользователь считается давшим согласие на обработку и хранение персональных данных, представленных в процессе регистрации в соответствии с действующим законодательством Республики Узбекистан, а также размещенных в личном кабинете, на предоставление третьим лицам. Обработка персональных данных осуществляется в соответствии с законодательством Республики Узбекистан.</p>

        <p>5.10. Администрация сайта может обрабатывать и хранить личную информацию пользователя, чтобы он мог пользоваться услугами сайта и анализировать соответствующую информацию, направленную на улучшение качества услуг сайта.</p>

        <p>5.11. Администрация Сайта принимает меры по предотвращению незаконного получения, изменения, раскрытия или уничтожения всей информации, связанной с идентификатором пользователя. Администрация сайта может разрешить другим работать с пользовательскими данными только в том случае, если это необходимо для настройки и обслуживания функций сайта.</p>

        <p>5.12. Администрация сайта может использовать предоставленную пользователем информацию, а также персональные данные в целях соблюдения требований законодательства Республики Узбекистан (в том числе в целях предупреждения или пресечения противоправных действий пользователя). Информация о пользователях может быть предоставлена иным лицам в соответствии с законодательством Республики Узбекистан, по договору или по требованию суда и иных правоохранительных и государственных органов, уполномоченных законодательством.</p>

        <p><strong>6. СТОИМОСТЬ И ПОРЯДОК ОПЛАТЫ ПЛАТНЫХ УСЛУГ</strong></p>

        <p>6.1. Платформа предоставляет платные услуги физическим и юридическим лицам. Стоимость и порядок оплаты платных услуг регулируются правилами, установленными настоящим Соглашением и/или самой платформой.</p>

        <p>6.2. Стоимость услуг определяется тарифами, установленными администрацией. Регистрация на платформе означает согласие Пользователя с ценой услуги, фиксированной стоимостью оплаты и условиями настоящего Соглашения.</p>

        <p>6.3. Стороны соглашаются и соглашаются с тем, что платформа будет выступать в качестве посредника между страховыми компаниями, банками, Единым порталом интерактивных государственных услуг и организациями-пользователями. Платформа не несет ответственности за стоимость услуг, установленную пользовательскими организациями, страховыми компаниями, банками и т. д.</p>

        <p>6.4. Платформа взимает фиксированную плату с пользователей в качестве посредника. В случае невыполнения платежей без уважительной причины администрация принимает меры по ограничению или прекращению услуг пользователей на платформе.</p>

        <p>6.5. В случае возникновения разногласий между получателями Услуги и соответствующими компаниями при использовании и взаимодействии с платформой Администрация не вмешивается и не решает проблемную ситуацию.</p>

        <p>6.6. Вопросы и споры о возврате денежных средств между организациями-партнерами и получателем услуг решаются отдельным соглашением между этими сторонами. При этом администрация не возвращает, не возмещает и не несет ответственности за возврат денежных средств. Плата, полученная за оказание услуг, не возвращается.</p>

        <p><strong>7. ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong></p>

        <p>7.1. Политика конфиденциальности данных регулирует отношения конфиденциальности данных на платформе.</p>

        <p>7.2. Правила конфиденциальности информации регулируются отдельным документом, который является частью настоящего Договора и указан в пункте 3.2 договора.</p>

        <p>7.3. Правила распространяются на всю информацию, которую сайт может получить о пользователе при регистрации и использовании. Регистрация и использование сайта пользователем подразумевает безусловное согласие с политикой конфиденциальности данных и условиями обработки на нем персональных данных пользователя. Если пользователь не согласен с этими условиями, он не должен использовать платформу.</p>

        <p>7.4. Стороны обязуются в соответствии с действующим законодательством Республики Узбекистан сохранять конфиденциальность информации, отнесенной сторонами к коммерческой тайне и известной сторонам в процессе совершения сделки, в том числе без ограничений:</p>

        <p>- сведения о плательщиках, объемах платежей;</p>

        <p>- техническая документация системы управления;</p>

        <p>- Сведения о размере заработной платы администрации и финансовых условиях договора.</p>

        <p>7.5. Стороны несут ответственность за разглашение конфиденциальной информации в соответствии с законодательством Республики Узбекистан.</p>

        <p><strong>8. ГАРАНТИИ И ПОЛНОМОЧИЯ СТОРОН</strong></p>

        <p>8.1. Платформа "Road24" предоставляет своим пользователям услуги гарантированного качества.</p>

        <p>8.2. Администрация не является стороной сделок купли-продажи, оказания услуг, выполнения работ и других сделок, заключаемых между получателем услуг и организациями-партнерами.</p>

        <p>8.3. Таким образом, каждая сторона заявляет и гарантирует другой стороне следующее:</p>

        <p>- оно создано и зарегистрировано в установленном порядке, в соответствии со статьями 14 и 15 Налогового кодекса Республики Узбекистан, является юридическим лицом, осуществляющим законную деятельность в соответствии с законодательством Республики Узбекистан, и имеет право осуществлять коммерческую, лицензируемую деятельность в соответствии с законодательством. Законодательство Республики Узбекистан имеет хорошую репутацию, обладает необходимыми возможностями, инвентарем, а также специалистами в своей области, гарантирует возможность исполнения своих обязательств перед администрацией и соответствующими государственными органами;</p>

        <p>- лицо, подписывающее настоящий договор, обладает всеми необходимыми полномочиями для этого действия;</p>

        <p>- стороны не несут ответственности перед третьими лицами по своим обязательствам;</p>

        <p>- ни одна из сторон не находится в процессе ликвидации. Для сторон нет никаких запретов, залогов, бремени или прав третьих лиц, препятствующих надлежащему исполнению настоящего Договора.</p>

        <p>8.4. Ни одна из сторон, ни ее директора, должностные лица или сотрудники не являются объектом санкции, не владеют, не контролируют и не действуют от имени другого разрешенного лица или юридического лица.</p>

        <p>8.5. Любые физические или юридические лица, привлеченные сторонами к исполнению своих обязательств по договору, включая их сотрудников, агентов, консультантов, подрядчиков/поставщиков и субподрядчиков/подрядчиков, подлежат обязательствам в соответствии с требованиями закона Узбекистана "О противодействии коррупции"(от 01.03.2017)., Орк- № 419):</p>

        <p>-не предлагать, не обещать, не давать, не требовать и не получать взятки или другие коррупционные платежи, в том числе государственному должностному лицу, за то, что это должностное лицо совершило какое-либо действие или бездействие при исполнении своих служебных обязанностей;</p>

        <p>-недопущение действий сторон, их прямых или косвенных акционеров (участников), директоров, должностных лиц или служащих, которые умышленно или по неосторожности могут привести к нарушению закона;</p>

        <p>- если у какой-либо из сторон имеются обоснованные подозрения в нарушении (или возможном нарушении) требований антикоррупционного законодательства Республики Узбекистан, они обязаны добросовестно сотрудничать с другой стороной и ее представителями.</p>

        <p><strong>9. ИНФОРМАЦИЯ О СТОРОНАХ</strong></p>

        <p>9.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение условий настоящего Договора в соответствии с действующим законодательством Республики Узбекистан.</p>

        <p>9.2. Пользователь несет ответственность за любой ущерб или другие неблагоприятные последствия, вызванные неисполнением или ненадлежащим исполнением обязательств по настоящему Договору.</p>

        <p>9.3. Пользователь несет единоличную ответственность за ввод своей личной информации на устройство, использующее ненадежные ссылки, или когда оно подвергается риску вредоносных программ и приложений.</p>

        <p>9.4. Сайт не несет ответственности за несоблюдение условий договора, если несоблюдение или несоблюдение условий договора было оправдано пользователем.</p>

        <p>9.5. Сайт не несет ответственности за ущерб, причиненный пользователю в связи с несвоевременным ознакомлением и (или) несвоевременным ознакомлением пользователя с условиями настоящего договора, а также его предложениями на момент активации.</p>

        <p>9.6. Если такое несоответствие произошло по причинам, не зависящим от сайта, сайт не несет ответственности за несоблюдение условий контракта. Объем надзора за платформой ограничен собственным функционированием сайта и не включает ответственность за сбои в работе, вызванные ошибками третьих лиц или сбоями в работе из-за запретов и ограничений, наложенных государственными органами или судебными органами.</p>

        <p>9.7. Стороны освобождаются от ответственности за невыполнение или ненадлежащее исполнение обязательств по настоящему Договору, если такое невыполнение является результатом форс-мажорных обстоятельств, возникших в результате чрезвычайных ситуаций, если сторона не смогла предвидеть или приняла разумные меры для предотвращения после заключения договора.</p>

        <p>9.8. Оператор полностью освобождается от ответственности за неисполнение полностью или частично своих обязательств по договору, если неисполнение или ненадлежащее исполнение произошло в результате форс-мажорных обстоятельств техногенного характера, а именно: аварии (сбоя в работе). Электрические или компьютерные сети или другие системы электрической связи, используемые для работы платформы, а также в результате умышленных незаконных действий третьих лиц, направленных на нарушение функционирования платформы.</p>

        <p>9.9. В случае утери, кражи или иных случаев, когда пользователь не имеет доступа к номеру мобильного (абонентского) телефона, к которому подключена платформа, пользователь несет все риски и ответственность за любые действия третьих лиц, связанные с использованием этой платформы.</p>

        <p>9.10. Оператор не несет ответственности за задержки и сбои в работе технических платформ пользователя и транспортных сетей или сетей связи, возникновение которых не происходит по вине оператора.</p>

        <p>9.11. Оператор не несет ответственности за любой ущерб, причиненный пользователю, поскольку он раскрыл свои учетные данные для доступа к платформе и / или другим личным данным пользователя.</p>

        <p>9.12. Оператор не несет ответственности за качество предоставляемых пользователю услуг организациями-партнерами, а также за свои обязанности в процессе и перед пользователями, связанные с предоставлением пользователям услуг, приобретенных пользователями.</p>

        <p>9.13. Пользователь несет ответственность за все операции, выполняемые им на платформе.</p>

        <p>9.14. Оператор не несет ответственности за качество линий связи, предоставляемых другими организациями, пользователями, и за работу оборудования других, в том числе транзитных, узлов сетей передачи данных, принадлежащих другим пользователям, к которым он не принадлежит.</p>

        <p>9.15. Пользователь несет риск и ответственность за любые действия третьих лиц, совершенные от имени пользователя, к которому подключена платформа, а также за использование приложений и приложений, установленных на его устройстве, или за использование его учетных данных для доступа к платформе.</p>

        <p><strong>10. РАСТОРЖЕНИЕ КОНТРАКТА</strong></p>

        <p>10.1. Договор может быть расторгнут в следующих случаях:</p>

        <p>- По соглашению сторон;</p>

        <p>- Односторонний в случае неоднократного нарушения одной или нескольких условий сделки другой стороной с учетом предусмотренного договором порядка разрешения споров;</p>

        <p>- По инициативе одной из сторон при условии уведомления другой стороны не позднее 30 (тридцати) календарных дней с предполагаемой даты расторжения договора. Уведомление должно быть сделано в письменной форме на бумаге и указывать причину расторжения договора.</p>

        <p>10.2. Администрация оставляет за собой право деактивировать учетную запись пользователя на платформе и расторгнуть договор, если пользователь нарушит условия настоящего Соглашения.</p>

        <p>10.3. Настоящий договор может быть расторгнут и по иным основаниям, предусмотренным законодательством.</p>

        <p>10.4. При отключении пользователем Личного кабинета договор считается расторгнутым. Перед отключением личного кабинета стороны должны выполнить обязательства, возникшие до расторжения договора.</p>

        <p><strong>11. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</strong></p>

        <p>11.1. Любой спор, вытекающий из настоящего Договора или связанный с ним, разрешается мирным путем - путем переговоров или в порядке апелляции по письменному запросу пользователя. Сроки рассмотрения обращения и требования к его содержанию определяются настоящим Договором и действующим законодательством Республики Узбекистан.</p>

        <p>11.2. Если спор не разрешен в исковом порядке, он может быть рассмотрен соответствующим судом в порядке, установленном действующим законодательством Республики Узбекистан.</p>

        <p><strong>12. ДРУГИЕ УСЛОВИЯ</strong></p>

        <p>12.1. Платформа интегрирована с базами данных организаций, совместно предоставляющих сопутствующие услуги, и данные пользователя принимаются этими организациями. Данные о пользователях также предоставляются платформе этими организациями.</p>

        <p>12.2. Некоммерческое использование контента платформы пользователем и третьими сторонами разрешено при условии, что все авторские права, имя автора и контент остаются неизменными.</p>

        <p>12.3. Сторонние сайты и контент:</p>

        <p>12.3.1. Сайт может содержать ссылки на статьи, фотографии, иллюстрации, графику, информацию, приложения, программы и другой контент, являющийся результатом интеллектуальной деятельности, охраняемой законодательством Республики Узбекистан, на других сайтах в интернете (сайты, принадлежащие третьим лицам). ).</p>

        <p>12.3.2. Контент, указанный как принадлежащий третьим лицам, не проверяется администрацией сайта на соответствие каким-либо требованиям (надежность, полнота, объективность). Администрация сайта не несет ответственности за любую информацию о третьих лицах, опубликованную на платформе, разрешенную пользователем или полученную из стороннего контента, а также за информацию, сообщенную третьими лицами или содержащуюся в их контенте.</p>

        <p>12.3.3. Информация о любой другой платформе, размещенная на платформе, любая информация о продукте, услуге коммерческого или некоммерческого характера не относится к содержанию, рекомендованному администрацией их сайта.</p>

        <p>12.4. Приняв это предложение, пользователь подтверждает свое безоговорочное согласие со всеми условиями договора и обязуется их соблюдать.</p>

        <p>12.5. Правила, инструкции, информация и рекомендации по их использованию на соответствующих платформах и разделах сайта являются неотъемлемой частью настоящего Соглашения. Используя эти платформы и разделы и функции сайта, пользователь соглашается с содержащимися в них правилами, инструкциями, информацией и рекомендациями.</p>

        <p>12.6. Срок действия настоящего Договора не ограничен. Если ни одна из сторон не выразила желания расторгнуть договорные отношения, договор считается продленным на следующий год.</p>

        <p>12.7. Эта публичная оферта является официальным документом, и прилагаемые к ней документы являются неотъемлемой частью публичной оферты.</p>

        <p>12.8. Стороны должны немедленно уведомлять друг друга в случае изменения их личной информации и вносить изменения в платформу.</p>

        <p>12.9. Иные отношения, не указанные в договоре, регулируются действующим законодательством Республики Узбекистан в соответствии с целями настоящего Договора.</p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
